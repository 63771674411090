<script>
export default {
  name: 'MarketTrends',
};
</script>
<template>
  <div id="market-trends">
    <section class="page-title page-title-1 image-bg overlay">
      <div class="background-image-holder">
        <img
          alt="Background Image"
          class="background-image"
          src="@/index/assets/img/market-trends.jpg"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1 class="uppercase mb0">Market Trends</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <h2>Advice for a prospective buyer</h2>
            <p class="lead mb80">
              An important initial step in the purchase process is to begin by
              working with a lender to clearly understand your budget. I have
              preferred lenders at every major lending institution. I work with
              a lot of first time buyers. 3rd step is to understand the market
              and educate yourself on available inventory and neighborhood
              nuances in San Francisco. 2nd step is to pull together your wish
              list of desired attributes such as bedroom and bathroom count,
              ideal location, better commute, outdoor space requirements and
              proximity to amenities. Next step is I will pull together all of
              the homes that match your wish list and the fun begins, time to go
              shopping! It’s typical for buyers to visit multiple properties
              until they find the place that feels like home. Once a property is
              identified, further due diligence is necessary to gather all the
              pertinent details of the property and review of the disclosures.
              Lastly, the most critical part of the buying process is
              structuring a compelling offer. After decades of transactions, my
              priority is my client’s experience and transparency in achieving
              their goal.
            </p>
            <hr  />
            <h2 class="mt80">Advice to sellers</h2>
            <p class="lead mb80">
              San Francisco is one of the most desirable real estate markets
              with continued strong demand and limited inventory. We want to
              optimize the value of your property. Step one is to evaluate the
              competitive landscape as well as look at the attributes of the
              home to determine fair market value. We want to present the home
              in the best light possible. It is amazing what a fresh coat of
              paint and decluttering of personal effects can do for a sale
              price. We want the prospective buyers to easily envision
              themselves in the space. When working with me, we offer Compass
              Concierge which covers the upfront costs to beautify the home for
              sale. From there, we will bring in our team of professional
              photographers, videographers and marketing specialists to showcase
              for as many potential buyers over multiple platforms.
            </p>
            <hr />
            <h2 class="mt80">Trends in the market</h2>
            <p class="lead">
              The average interest rate on 30-year mortgages has remained near
              historic lows. Today’s 30-year fixed mortgage rates are hovering
              near 3%! So what does that mean? Essentially, with declining
              inventory available of homes and borrowing money being a lot less
              expensive, more competition. Interest rates remaining near
              historic lows bodes well for buyers, and today’s market reflects
              some of the cheapest debt a home buyer will be able to attain in
              the market.
            </p>
            <p class="lead">
              Most importantly, finding the right mortgage depends on receiving
              the right advice from a seasoned real estate expert who has
              personal experience and has transacted numerous real estate sales
              in a variety of market conditions. Working with an expert enables
              prospective investors to feel more knowledgeable, confident, and
              secure with their financial decisions.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
