<script>
import { ref } from "vue";
import ListingApi from "@/http/listing";
import { chunk, groupBy, sortBy } from "lodash";
import { formatCurrency } from "@/composition/deps";

export default {
  name: "Listings",

  async setup() {
    const sections = ref([]);
    const listings = await ListingApi.getAllResources();

    sections.value = groupBy(
      listings.sort((a, b) => b.price - a.price),
      "status"
    );

    const sectionsOrder = ["Active", "Coming Soon"];

    const sectionsOrder1 = ["Pending", "Sold"];

    const chunkListings = (sectionListings) => chunk(sectionListings, 4);

    const openLink = (url) => window.open(url, "_blank");

    return {
      openLink,
      sections,
      chunkListings,
      sectionsOrder,
      sectionsOrder1,
      formatCurrency,
    };
  },
};
</script>
<template>
  <teleport to="head">
    <title>Kristen Stuecher - Listings</title>
  </teleport>
  <section class="page-title page-title-1 image-bg overlay">
    <div class="background-image-holder">
      <img
        alt="Background Image"
        class="background-image"
        src="@/index/assets/img/small1.jpg"
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h1 class="uppercase mb0">Listings</h1>
        </div>
      </div>
    </div>
  </section>
  <section class="projects bg-secondary">
    <div class="container-fluid">
      <template v-for="sectionName in sectionsOrder" :key="sectionName">
        <div v-show="sections[sectionName]" class="row fadeIn">
          <div class="col-md-12">
            <h2>{{ sectionName }}</h2>
            <hr />
          </div>
          <div class="col-md-12">
            <template
              v-for="(sectionListings, sectionIdx) in chunkListings(
                sections[sectionName]
              )"
              :key="sectionIdx"
            >
              <div class="row mb64">
                <div
                  v-for="(listing, idx) in sectionListings"
                  :key="idx"
                  @click="openLink(listing.website)"
                  class="col-md-3 col-sm-6 project-full fadeIn"
                >
                  <div class="image-tile text-center">
                    <span class="label">
                      {{ listing.status }}
                    </span>
                    <img
                      alt="image"
                      class="background-image"
                      :src="listing.fileurl"
                    />
                    <div class="show-details-tile">
                      <h5 class="uppercase mb0">
                        {{ listing.address_1 }}
                      </h5>
                      <h5 class="uppercase mb8">
                        {{ listing.address_2 }}
                      </h5>

                      <h5>
                        <template v-if="listing.price > 0">
                          {{ formatCurrency(listing.price) }}
                        </template>
                        <template v-else>
                          {{ listing.altPrice }}
                        </template>
                        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{
                          listing.bedrooms
                        }}
                        BD - {{ listing.fullbaths }} BA
                      </h5>
                    </div>
                  </div>
                  <!--end of hover tile-->
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-for="sectionName in sectionsOrder1" :key="sectionName">
        <div v-show="sections[sectionName]" class="row fadeIn">
          <div class="col-md-12">
            <h2>{{ sectionName }}</h2>
            <hr />
          </div>
          <div class="col-md-12">
            <template
              v-for="(sectionListings, sectionIdx) in chunkListings(
                sections[sectionName]
              )"
              :key="sectionIdx"
            >
              <div class="row mb64">
                <div
                  v-for="(listing, idx) in sectionListings"
                  :key="idx"
                  @click="openLink(listing.website)"
                  class="col-md-3 col-sm-6 project fadeIn"
                >
                  <div class="image-tile hover-tile text-center">
                    <span class="label">
                      {{ listing.status }}
                    </span>
                    <img
                      alt="image"
                      class="background-image"
                      :src="listing.fileurl"
                    />
                    <div class="hover-state">
                      <a :href="listing.website" target="_blank">
                        <h5 class="uppercase mb0">
                          {{ listing.address_1 }}
                        </h5>
                        <h5 class="uppercase">
                          {{ listing.address_2 }}
                        </h5>

                        <h6>
                          {{ formatCurrency(listing.price) }}
                          <template
                            v-if="listing.bedrooms && listing.fullbaths"
                          >
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{
                              listing.bedrooms
                            }}
                            BD - {{ listing.fullbaths }} BA
                          </template>
                        </h6>
                      </a>
                    </div>
                  </div>
                  <!--end of hover tile-->
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <!--end of row-->
  </section>
</template>
<style scoped lang="scss">
.show-details-tile {
  padding: 10px;
  background: #434343;
  h4 {
    color: #fff !important;
    font-size: 20px;
  }

  h5 {
    color: #fff !important;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.project {
  max-height: 290.5px;
  padding: 20px;
  .image-tile {
    max-height: 290.5px;
    cursor: pointer;
  }
}

.project-full {
  padding: 20px;
  .image-tile {
    cursor: pointer;
  }
}

.label-left {
  color: white;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 5;
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  letter-spacing: 1px;
  background: #434343;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 10px;
  vertical-align: middle;
}
</style>
