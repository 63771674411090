<script>
import ImageBlock from "@/components/ImageBlock.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextarea from "@/components/BaseTextarea.vue";
import PostCarousel from "@/index/views/Home/PostCarousel.vue";
import PostApi from "@/http/post";

export default {
  name: "Home",

  data() {
    return {
      posts: [],
      error: "",
      success: "",
      form: {
        name: {
          value: "",
          validate: (val) => val.length >= 4,
        },
        email: {
          value: "",
          validate: (val) =>
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              val
            ),
        },
        phone: {
          value: "",
          validate: () => true,
        },
        message: {
          value: "",
          validate: (val) => val.length > 5,
        },
      },
      slideImages: ["home-bg", "home-bg1", "home-bg2", "home-bg3"],
      stats: [
        "TOP 5% OF REALTORS IN THE US",
        "7 DAYS UNDER CITY AVERAGE ON THE MARKET",
        "40 UNDER 40",
        "TOP 50 REALTORS IN SF",
        "5.6% ABOVE ASKING PRICE ON AVERAGE",
        "10% OF ALL PROCEEDS GO TO CHARITY",
      ],
    };
  },

  components: {
    ImageBlock,
    BaseInput,
    BaseTextarea,
    PostCarousel,
  },

  mounted() {
    this.initializeSliders();
  },

  beforeRouteEnter(to, from, next) {
    PostApi.getAllResources({ featured: true }).then((posts) => {
      next((vm) => (vm.posts = posts));
    });
  },

  methods: {
    initializeSliders() {
      if (
        $(
          ".slider-all-controls, .slider-paging-controls, .slider-arrow-controls, .slider-thumb-controls, .logo-carousel"
        ).length
      ) {
        $(".slider-all-controls").flexslider({
          start(slider) {
            if (
              slider
                .find(".slides li:first-child")
                .find(".fs-vid-background video").length
            ) {
              slider
                .find(".slides li:first-child")
                .find(".fs-vid-background video")
                .get(0)
                .play();
            }
          },
          after(slider) {
            if (slider.find(".fs-vid-background video").length) {
              if (
                slider
                  .find("li:not(.flex-active-slide)")
                  .find(".fs-vid-background video").length
              ) {
                slider
                  .find("li:not(.flex-active-slide)")
                  .find(".fs-vid-background video")
                  .get(0)
                  .pause();
              }
              if (
                slider
                  .find(".flex-active-slide")
                  .find(".fs-vid-background video").length
              ) {
                slider
                  .find(".flex-active-slide")
                  .find(".fs-vid-background video")
                  .get(0)
                  .play();
              }
            }
          },
        });
        $(".slider-paging-controls").flexslider({
          animation: "slide",
          directionNav: false,
        });
        $(".slider-arrow-controls").flexslider({
          controlNav: false,
        });
        $(".slider-thumb-controls .slides li").each(function () {
          const imgSrc = $(this).find("img").attr("src");
          $(this).attr("data-thumb", imgSrc);
        });
        $(".slider-thumb-controls").flexslider({
          animation: "slide",
          controlNav: "thumbnails",
          directionNav: true,
        });
        $(".logo-carousel").flexslider({
          minItems: 1,
          maxItems: 4,
          move: 1,
          itemWidth: 200,
          itemMargin: 0,
          animation: "slide",
          slideshow: true,
          slideshowSpeed: 3000,
          directionNav: false,
          controlNav: false,
        });
      }
    },

    postForm() {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: "/api/contactform",
          method: "POST",
          contetType: "application/json",
        })
          .done(resolve)
          .fail(reject);
      });
    },

    submitForm() {
      this.success = "";
      this.error = "";

      if (this.validate()) {
        const payload = this.form;
        this.postForm(payload)
          .then(() => {
            this.success = "Message successfully sent.";
          })
          .catch(() => {
            this.error =
              "There was a problem processing your request. Please try again later";
          });
      } else {
        this.error = "Please fill out all required fields.";
      }
    },

    validate() {
      return Object.keys(this.form).every((key) =>
        this.form[key].validate(this.form[key].value)
      );
    },
  },
};
</script>
<template>
  <teleport to="head">
    <title>Kristen Stuecher Real Estate</title>
  </teleport>
  <div id="home">
    <section
      class="
        cover
        fullscreen
        image-slider
        slider-all-controls
        controls-inside
        parallax
      "
    >
      <ul class="slides">
        <li
          v-for="(img, idx) in slideImages"
          class="overlay image-bg"
          :key="idx"
        >
          <div class="background-image-holder">
            <img
              alt="image"
              class="background-image"
              :src="require(`@/index/assets/img/${img}.jpg`)"
            />
          </div>
          <div class="container v-align-transform">
            <div class="row">
              <div class="col-sm-10 col-sm-offset-1 text-center">
                <h1 class="mb40 mb-xs-16 uppercase">
                  Kristen Stuecher Real Estate
                  <br />
                </h1>
                <h4 class="uppercase mb16">Representing Buyers & Sellers of Distinguished San Francisco Properties</h4>
                <h5 class="uppercase mb40">
                  Exceeding your expectations is our Top Priority
                </h5>
                <router-link to="/contact-us" class="btn btn-white btn-lg"
                  >Contact Me</router-link
                >
                <router-link to="/listings" class="btn btn-white btn-lg"
                  >Featured Listings</router-link
                >
              </div>
            </div>
            <!--end of row-->
          </div>
          <!--end of container-->
        </li>
      </ul>
    </section>

    <section class="pb120 pt-xs-40 pb-xs-40">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-md-offset-1 text-center">
            <h3>OUR PERFORMANCE</h3>

            <p class="lead">
              Buying or selling your home is an exciting time - especially in
              the Bay Area. It is important to have a real estate agent team
              with a strong track record of repeat buyers and sellers. We have
              built our reputation as one of the top real estate agents in San
              Francisco by partnering with you at each step, from listing a
              property to searching for your dream home to transaction. Customer
              service and market knowledge are what drives our team.
            </p>
            <p class="lead">
              The market in San Francisco and the Bay Area is one of the most
              desired. In working with sellers, we strive to prepare, price, and
              market each home for optimization. Our closing rate is something
              we are proud of. For buyers, our agents are knowledgeable about
              the things that matter most: top school districts, areas with the
              best resale estimates, creating the best offer for you, and all of
              the hidden gems in the nooks and crannies of buying a home.
            </p>
            <p class="lead">
              Our track record speaks for itself. We are continuously among the
              top real estate agents in San Francisco. Testimonials from our
              clients speak for themselves. Even in this overhanging market,
              over 86% of our buyers get into contract with their first offer.
              For sellers, our average list-to-sale price is 5.6% higher than
              the city average. We work diligently to implement the best
              strategies to achieve your goals.
            </p>

            <div class="row">
              <div v-for="(stat, idx) in stats" class="col-md-2" :key="idx">
                <div class="circle_container">
                  <div class="circle_main">
                    <div class="circle_text_container">
                      <div class="circle_text">
                        {{ stat }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <hr class="mb64" />
        </div>
      </div>
    </div>

    <section class="pb120 pt-xs-40 pb-xs-40">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <div class="row">
              <div class="col-md-7">
                <h3 class="mb40 mb-xs-16">MEET KRISTEN STUECHER</h3>
                <p class="lead blockquote mb40 bio-text">
                  Kristen has been a top real estate agent for San Francisco and
                  the Bay Area for the past 15 years with over $400 million in
                  recent closed transactions. Kristen has continuously ranked
                  with top San Francisco real estate professionals because of
                  her knowledge, patience, and enjoyment of building lasting
                  relationships. Kristen and her team make sure each and every
                  client receives expert guidance. With the goal of creating
                  lifetime clients, Kristen is dedicated to providing an
                  unsurpassed level of personalized services for buyers and
                  sellers alike. Her commitment to supporting her clients and
                  their wishes is proven by her results, and her transparency
                  throughout the process ensures her client’s trust in her
                  abilities.
                </p>
                <router-link to="/about-us" class="btn bg-dark uppercase"
                  >About Us</router-link
                >
              </div>
              <div class="col-md-4 col-md-offset-1">
                <img
                  alt="Screenshot"
                  class="mb-xs-24 profile-picture"
                  src="@/index/assets/img/ks.jpg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end of container-->
    </section>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <hr class="mb64" />
        </div>
      </div>
    </div>

    <ImageBlock img-name="block-img-1.jpg" orientation="left">
      <h3 class="uppercase">Ready to sell or buy?</h3>
      <p class="mb0 lead">
        When you make a decision to work with me, you're not just getting an
        agent who utilizes the best tools and technology to help you with your
        real estate transaction, you're getting transparency and instant
        communication throughout the entire process, so you can relax knowing
        every detail is taken care of.
      </p>

      <router-link to="/contact-us" class="btn bg-dark uppercase mt32">
        Get In Touch
      </router-link>
    </ImageBlock>
    <ImageBlock img-name="block-img-2.jpg" orientation="right">
      <h3 class="uppercase">MAXIMIZE THE VALUE OF YOUR HOME</h3>
      <p class="mb0 lead">
        Compass Concierge is the hassle-free way transform your home with zero
        upfront costs or interest. I'll work with you to determine how we can
        maximize the value of your home by taking advantage of select Compass
        Concierge Services.
        <br />
        <br />
        With Concierge, you do not have to pay the initial out-of-pocket costs
        for home improvement services like painting, staging, flooring and more.
        When your gome sells you simply pay back the cost of the services
        provided
      </p>
      <a
        target="_blank"
        href="https://www.compass.com/concierge/kristen-stuecher/"
        class="btn bg-dark uppercase mt32"
      >
        Learn More
      </a>
    </ImageBlock>

    <section class="bg-secondary pb16">
      <div class="container-fluid">
        <div class="row mb64 mb-xs-24">
          <div class="col-md-10 col-md-offset-1 col-sm-12 text-center">
            <h3>KRISTEN IN THE NEWS!</h3>
          </div>
        </div>

        <PostCarousel v-if="posts.length" :posts="posts"></PostCarousel>


        <!-- <hr />
        <div class="row">
          <div class="news-logos">
            <div>
              <img src="@/index/assets/img/news-logos/download.png" alt="" />
            </div>
            <div>
              <img src="@/index/assets/img/news-logos/download-1.png" alt="" />
            </div>
            <div>
              <img src="@/index/assets/img/news-logos/download-2.png" alt="" />
            </div>
            <div>
              <img src="@/index/assets/img/news-logos/download-3.png" alt="" />
            </div>
            <div>
              <img src="@/index/assets/img/news-logos/download-4.png" alt="" />
            </div>
            <div>
              <img src="@/index/assets/img/news-logos/download-5.png" alt="" />
            </div>
            <div>
              <img src="@/index/assets/img/news-logos/download-6.png" alt="" />
            </div>
          </div>
        </div> -->
      </div>
    </section>

    <section class="bg-secondary pb32 pt8">
      <div class="container-fluid">
        <div class="row">
          <div class="news-logos">
                        <div>
              <a href="https://www.sfgate.com/realestate/article/A-long-search-bears-fruit-San-Francisco-man-3199249.php" target="_blank" rel="noopener noreferrer">
                <img src="@/index/assets/img/news-logos/download.png" alt="" />
              </a>
            </div>
            <div>
              <a href="https://www.sfchronicle.com/bayarea/article/Live-work-lofts-blend-form-function-14984399.php" target="_blank" rel="noopener noreferrer">
                <img src="@/index/assets/img/news-logos/download-1.png" alt="" />
              </a>
            </div>
            <div>
              <a href="https://sf.curbed.com/2017/11/15/16658544/condo-inside-historic-clock-tower-building-seeks-1-3-million" target="_blank" rel="noopener noreferrer">
                <img src="@/index/assets/img/news-logos/download-2.png" alt="" />
              </a>
            </div>
            <div>
              <a href="https://www.elledecor.com/design-decorate/house-interiors/a19577935/mid-century-modern-homes-san-francisco-real-estate/" target="_blank" rel="noopener noreferrer">
                <img src="@/index/assets/img/news-logos/download-3.png" alt="" />
              </a>
            </div>
            <div>
              <a
                href="https://www.homelight.com/blog/top-real-estate-agents-in-san-francisco/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/index/assets/img/news-logos/download-4.png"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a href="https://www.bizjournals.com/sanfrancisco/news/2019/05/22/renovated-church-dolores-st-light-house-townhouse.html" target="_blank" rel="noopener noreferrer">
                <img src="@/index/assets/img/news-logos/download-5.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="image-bg overlay pt120 pb120 pt-xs-40 pb-xs-40 testimonials"
    >
      <div class="background-image-holder blur-image">
        <img
          alt="image"
          class="background-image"
          src="@/index/assets/img/meet-kristen.jpg"
        />
      </div>
      <div class="container">
        <!--end of row-->
        <div class="row mb16 mb-xs-0">
          <div class="col-sm-12 text-center">
            <h3>CLIENT EXPERIENCES & TESTIMONIALS</h3>
          </div>
        </div>
        <!--end of row-->
        <div class="row">
          <div class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
            <div class="text-slider slider-arrow-controls text-center relative">
              <ul class="slides">
                <li>
                  <p class="lead">
                    "Oh, the patience Kristen has to work with first-time buyers
                    is admirable. Thank you for helping us buy our first home in
                    San Franciso. It took a year and a half and 4 offers and you
                    were extremely knowledgable, helpful and transparent. You
                    never pushed any agendas and let us take our time to decide
                    what's best for us. Kristen came highly recommended by more
                    than one friend when we started working with her, and the
                    recommendations were spot on."
                  </p>
                  <div class="quote-author">
                    <h6 class="uppercase mb0 stars">★★★★★</h6>
                  </div>
                </li>
                <li>
                  <p class="lead">
                    "We purchased our first home in May 2019. During the process
                    Kristen was very responsive, informative, and helpful,
                    answering our first-time-buyer questions with patience and
                    sensitivity. She helped guide us to opportunities that would
                    fit our needs/wants and, most importantly, our budget. We're
                    so pleased with our new home and the process ended up being
                    much simpler and more doable than we first anticipated."
                  </p>
                  <div class="quote-author">
                    <h6 class="uppercase mb0 stars">★★★★★</h6>
                  </div>
                </li>
                <li>
                  <p class="lead">
                    "As first time home buyers, we didn't know the first thing
                    about purchasing a home. We met with Kristen and were
                    immediately relieved to have someone experienced and on our
                    side helping us through the process. Kristen is patient and
                    explains things very clearly. She got to know what we liked
                    and more importantly, what we didn't like. We ended up
                    buying a house fairly quickly and the process was seamless.
                    I would recommend for anyone looking for an agent to contact
                    Kristen!"
                  </p>
                  <div class="quote-author">
                    <h6 class="uppercase mb0 stars">★★★★★</h6>
                  </div>
                </li>
                <li>
                  <p class="lead">
                    "I met Kristen in 2010 and have had the benefit of her sound
                    advice and local expertise ever since. Through multiple
                    transactions (buying and selling) Kristen has always been
                    reliable, ensured smooth seamless interactions from
                    house-hunting to close of escrow. My husband and I felt
                    comfortable talking through pros/cons of all our options,
                    texting to keep in sync, and emailing with Kristen. Her
                    approach works really well for us - no pressure to buy or
                    sell - she knows we always come to her when we're ready to
                    move forward. When you find someone like Kristen you keep
                    coming back!"
                  </p>
                  <div class="quote-author">
                    <h6 class="uppercase mb0 stars">★★★★★</h6>
                  </div>
                </li>
                <li>
                  <p class="lead">
                    "Kristen Stuecher has helped me buy two properties and sell
                    one over the past eight years. She’s always been
                    professional, knowledgeable and responsive, she’s helped
                    guide me into making good decisions (and avoiding a few bad
                    ones), and I’m so appreciative of all the hard work she’s
                    done on my behalf. I can’t recommend her more highly."
                  </p>
                  <div class="quote-author">
                    <h6 class="uppercase mb0 stars">★★★★★</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--end of row-->
      </div>
      <!--end of container-->
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-md-5">
            <h4 class="uppercase">Get In Touch</h4>
            <p class="lead">
              I love where I live and you should too! Let me help you reach your
              dreams!
            </p>
          </div>
          <div class="col-sm-6 col-md-5 col-md-offset-1">
            <form @submit.prevent="submitForm" class="contact-form">
              <BaseInput
                v-model="form.name.value"
                type="text"
                class="validate-required"
                placeholder="Your Name *"
              />
              <BaseInput
                v-model="form.email.value"
                type="text"
                class="validate-required validate-email"
                placeholder="Email Address *"
              />
              <BaseInput
                v-model="form.phone.value"
                type="text"
                class="validate-required validate-email"
                placeholder="Phone"
              />
              <BaseTextarea
                v-model="form.message.value"
                class="validate-required mb0"
                rows="4"
                placeholder="Message *"
              ></BaseTextarea>
              <div class="mb16">
                <small>* Required fields</small>
              </div>

              <button class="btn bg-dark" type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss">
#home {
  .profile-picture {
    height: 370px;
  }
  .bio-text {
    font-style: italic;
  }
  .stars {
    font-size: 20px;
  }

  .post-image-container {
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blog-image {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blur-image {
    filter: blur(8px);
    -webkit-filter: blur(5px);
  }

  .testimonials {
    .lead {
      font-size: 18px;
    }
  }

  .circle_container {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    padding: 0;
  }

  .circle_main {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #d9d9d9;
    margin: 0;
    padding: 0;
  }

  .circle_text_container {
    width: 70%;
    height: 70%;
    max-width: 70%;
    max-height: 70%;
    margin: 0;
    padding: 0;
    position: relative;
    left: 15%;
    top: 15%;
    transform-style: preserve-3d;
  }

  .circle_text {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 18px;
    color: black;
  }
}

.carousel__prev,
.carousel__next {
  background-color: #ff000000 !important;
}

.carousel__prev {
  left: 3% !important;
}

.carousel__next {
  right: 3% !important;
}

.news-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 17%;
  & > div {
    // width: 14.28571%;
    width: 140px; /* Or whatever */
    height: 100px; /* Or whatever */
    margin: auto;
    position: relative;
    & > a {
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}
</style>
