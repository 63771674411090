<script>
import { ref } from "vue";
import TestimonialApi from "@/http/testimonial.js"
export default {
  name: 'Testimonials',

  async setup() {
    const testimonials = ref([]);

    testimonials.value = await TestimonialApi.getAllResources()

    return {
      testimonials
    }
  }
};
</script>
<template>
  <teleport to="head">
    <title>
      Kristen Stuecher - Testimonials
    </title>
  </teleport>
  <div id="market-trends">
    <section class="page-title page-title-1 image-bg overlay">
      <div class="background-image-holder">
        <img
          alt="Background Image"
          class="background-image"
          src="@/index/assets/img/testimonials-bg.jpg"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1 class="uppercase mb0">Testimonials</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div v-for="(testimonial, idx) in testimonials" :key="testimonial.id" class="row">
          <div class="col-md-10 col-md-offset-1">
            <h4 class="mb16">{{ testimonial.name }}</h4>
            <p class="lead mb16 testimonial-text">
              &ldquo;{{ testimonial.text }}&rdquo;
            </p>
            <hr v-show="idx + 1 !== testimonials.length" class="mb64 mt64" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.testimonial-text {
  text-align: justify;
  font-style: italic;
}
</style>
