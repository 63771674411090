<template>
    <div class="row">
        <div class="col-md-12">
            <Carousel :settings="settings" :breakpoints="breakpoints">
                <Slide v-for="(post, idx) in posts" :key="post.id">
                    <div class="carousel__item">
                        <a
                            target="_blank"
                            class="post-image-container"
                            :href="post.is_link ? post.body : `/blog/${post.slug}`"
                        >
                            <img
                                alt="Pic"
                                class="mb24 blog-image"
                                :src="post.coverurl"
                            />
                        </a>
                        <a target="_blank" :href="post.is_link ? post.body : `/blog/${post.slug}`">
                            <h4 class="mb8 text-center">
                                {{ post.title }}
                            </h4>
                        </a>
                    </div>
                </Slide>

                <template #addons>
                    <navigation>
                        <template #next>
                            <i class="flex-prev"></i>
                        </template>
                        <template #prev>
                            <i class="flex-next"></i>
                        </template>
                    </navigation>
                </template>
            </Carousel>
        </div>
    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";

export default {
    name: "Post-Carousel",

    components: {
        Carousel,
        Slide,
        Navigation,
    },

    props: {
        posts: {
            type: Array,
            required: true,
            default: function () {
                return [];
            },
        },
    },

    data: () => ({
        settings: {
            itemsToShow: 3,
            snapAlign: "start",
            loop: true,
        },
        breakpoints: {
            // 700: {
            //     itemsToShow: 3.5,
            //     snapAlign: "center",
            // },
            // 1024: {
            //     itemsToShow: 3,
            //     snapAlign: "start",
            // },
        },
    }),

    methods: {
        getDate(date = new Date()) {
            const options = { dateStyle: "medium" };
            const formatter = new Intl.DateTimeFormat("en", options);

            return formatter.format(new Date(date));
        },
    },
};
</script>
<style scoped>
section.carousel {
  padding: 10px 0;
}

.carousel__item {
    padding: 10px;
}
.carousel__prev, .carousel__next {
  background-color: #ff000000 !important;
}

.carousel__prev {
  left: 4% !important;
}

.flex-next::before {
    content: "\e64a";
    font-family: 'themify';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 24px;
    color: #fff;
}

.flex-prev::before {
      content: "\e649";
    font-family: 'themify';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 24px;
    color: #fff;
}

@import "~vue3-carousel/dist/carousel.css";
</style>