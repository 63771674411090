<script>
export default {
  name: 'Footer',
};
</script>
<template>
  <footer class="footer-1 bg-dark">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <span class="logo-name"> Kristen Stuecher </span>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="widget">
            <h6 class="title">Contact Me</h6>
            <hr />
            <div>
              <span> 891 Beach Street </span>
              <br />
              <span> San Francisco, CA 94109 </span>
              <br />
              <br />
              <span>M: <a href="tel:415-948-4888">415-948-4888</a></span>
              <br />
              <span>
                <a href="mailto:kristen.stuecher@compass.com"
                  >kristen.stuecher@compass.com</a
                >
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="widget">
            <h6 class="title">Lets Get Social</h6>
            <hr />
            <div>
              <ul class="social-list">
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/kristenstuecher/"
                  >
                    <i class="ti-instagram mr-10"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                  target="_blank"
                  href="https://www.facebook.com/KristenStuecherRE/">
                    <i class="ti-facebook mr-10"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                  target="_blank"
                    href="https://www.linkedin.com/in/kristen-stuecher-9349246/"
                  >
                    <i class="ti-linkedin mr-10"></i>
                    <span>LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>

    <a class="btn btn-sm fade-half back-to-top inner-link" href="#top">Top</a>
  </footer>
</template>
<style>
.social-list { color: white !important; }
</style>
