<template>

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-10 col-md-offset-1">
                    <iframe src="https://www.compass.com/c/kristen-stuecher/san-francisco-homes?agent_id=5e2f498d04d3012f1e01c9a0" width="100%" height="650" style="border:none;"></iframe>
                </div>
            </div>
        </div>
</template>
<script>
export default {
  name: 'Collections',
};
</script>
